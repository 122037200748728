import React from 'react';
import { Navigation } from './Navigation';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './config/styles/theme';
import { useAuth } from './hooks/useAuth';
import { SignIn } from './screens/signIn';
import { ToastProvider } from '@inventures/react-lib';
import { ApolloProvider } from './config/apollo';
function withProviders(Component: React.ElementType) {
  return function ComponentWithProviders() {
    return (
      <ApolloProvider>
        <ToastProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline></CssBaseline>
            <Component />
          </ThemeProvider>
        </ToastProvider>
      </ApolloProvider>
    );
  };
}
function App() {
  const { user } = useAuth();
  return user ? <Navigation /> : <SignIn />;
}

export default withProviders(App);
