/*
 * File: Loader.tsx
 * Project: meki
 * File Created: Wednesday, 6th January 2021 11:48:15 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Wednesday, 6th January 2021 11:49:02 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100vh',
      position: 'absolute',
      background: ' rgba(0,0,0,0.6)',
      zIndex: 9999999,
      top: 0,
      right: 0,
      left: 0,
      color: theme.palette.primary.dark,
    },
  }),
);

export function Loader() {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <CircularProgress />
    </div>
  );
}
