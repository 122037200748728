/*
 * File: theme.js
 * Project: meki
 * File Created: Wednesday, 9th September 2020 12:08:48 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 11th January 2021 11:44:23 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { createMuiTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0277BD',
      light: '#58A5F0',
      dark: '#004C8C',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00848F',
      light: '#4FB3BF',
      dark: '#005662',
      contrastText: '#fff',
    },
    success: {
      main: '#4CAF50',
      light: '#81C784',
      dark: '#388E3C',
    },
    info: {
      main: '#2196F3',
      light: '#BBDEFB',
      dark: '#0B79D0',
    },
    warning: {
      main: '#FF9800',
      light: '#FFB74D',
      dark: '#F57C00',
    },
    error: {
      main: '#F44336',
      light: '#E57373',
      dark: '#D32F2F',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontSize: '93px',
      lineHeight: '131px',
      fontWeight: 300,
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: '58px',
      lineHeight: '81px',
      fontWeight: 400,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: '46px',
      lineHeight: '64px',
      fontWeight: 400,
      letterSpacing: '0px',
    },
    h4: {
      fontSize: '33px',
      lineHeight: '47px',
      fontWeight: 400,
      letterSpacing: '0.25px',
    },
    h5: {
      fontSize: '23px',
      lineHeight: '32px',
      fontWeight: 400,
      letterSpacing: '0px',
    },
    h6: {
      fontSize: '19px',
      lineHeight: '27px',
      fontWeight: 500,
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '15px',
      lineHeight: '21px',
      fontWeight: 400,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '13px',
      lineHeight: '19px',
      fontWeight: 500,
      letterSpacing: '0.1px',
    },
    body1: {
      fontSize: '15px',
      lineHeight: '21px',
      fontWeight: 400,
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '13px',
      lineHeight: '19px',
      fontWeight: 400,
      letterSpacing: '0.15px',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '17px',
      fontWeight: 400,
      letterSpacing: '0.4px',
    },
    overline: {
      fontSize: '10px',
      lineHeight: '15px',
      fontWeight: 400,
      letterSpacing: '1.5px',
    },
    button: {
      fontSize: '13px',
      lineHeight: '19px',
      fontWeight: 500,
      letterSpacing: '1.25px',
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiInputBase: { root: { fontSize: 16 } },
    MuiButton: {
      contained: {
        borderRadius: 25,
        height: 42,
      },
      outlined: {
        borderRadius: 25,
        height: 42,
        fontSize: 14,
      },
    },
  },
});

export default theme;
