/*
 * File: getAllProducts.ts
 * Project: admin
 * File Created: Thursday, 4th March 2021 5:34:07 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 12th April 2021 11:19:43 am
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { gql } from '@apollo/client';
import { ProductConnection } from 'src/interfaces';
import { ProductBaseFragment } from '../fragments';

export const getAllProducts = (name: string) => gql`
    query ${name} ($params: ProductsParams){
        products(params: $params) {
          nodes{
            ...ProductBase
          }
        pageInfo{
          totalPages
          pageNumber
        }
      }
    }
    ${ProductBaseFragment}
`;

export type GetAllProductsResponse = {
  products: ProductConnection;
};
