/*
 * File: ProductBase.ts
 * Project: admin
 * File Created: Tuesday, 16th March 2021 12:13:25 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Friday, 30th April 2021 10:35:27 am
 * Modified By: Vicente Melin (vicente@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { gql } from '@apollo/client';

export const ProductBaseFragment = gql`
  fragment ProductBase on Product {
    uuid
    id
    name
    makerBrand
    concentration
    format
    quantity
    unit
    container
    containerQuantity
    laboratory
    metadata {
      laboratoryNickname
      equivalence
      officialPrescriptionType
      cvPrescriptionType
      legalControl
      cvSKU
      ispRegister
      internalId
      matchRisk
      min
      description
      originalTradename
      pharolImage
      objectID
      fasaSku
      computedAvailability
    }
    deletedAt
    displayStatus
    availability {
      availability
    }
  }
`;
