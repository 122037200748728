/*
 * File: index.tsx
 * Project: admin
 * File Created: Thursday, 4th March 2021 5:32:35 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 19th April 2021 1:42:41 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useLazyQuery } from '@apollo/client';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  getAllProducts,
  GetAllProductsResponse,
} from '../../queries/products/queries';
import ProductList from './components/ProductList';

import { Fab, LinearProgress, TextField } from '@material-ui/core';
import { useProductStyles } from './styles';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { CreateProductModal } from './components';
import { Product } from 'src/interfaces';
import { useSearchBar } from '@inventures/react-lib';
import { useToast } from '@inventures/react-lib';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';

export function Products() {
  const classes = useProductStyles();
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);

  const queryParams = Object.fromEntries(searchParams);

  const { showToast } = useToast();
  const lastQueryCallRef = useRef('');
  const [call, { data, loading, error }] = useLazyQuery<GetAllProductsResponse>(
    getAllProducts('SearchResultByQueryParams'),
    {
      variables: {
        params: {
          query: queryParams.query,
          pageNumber: queryParams.page ? Number(queryParams.page) : 1,
        },
      },
    },
  );
  useEffect(() => {
    // console.log({ query: queryParams?.query, last: lastQueryCallRef.current });
    if (
      queryParams?.query?.length > 3 &&
      queryParams.query !== lastQueryCallRef.current
    ) {
      // console.log('call');
      lastQueryCallRef.current = queryParams.query;
      call();
    }
  }, [call, queryParams.query]);

  const [open, setOpen] = useState(false);
  const debounceTime = 500;

  const filterOptions = {
    debounceTime,
    isCaseSensitive: false,
  };

  const productsList = useMemo<Product[]>(() => {
    if (data) {
      if (data.products.nodes) {
        return data.products.nodes;
      }
    }
    return [];
  }, [data]);

  const [, setSearchValue] = useSearchBar(
    queryParams.query,
    (input: string) => {
      const inputsValues = new URLSearchParams(input);
      const params = Object.fromEntries(inputsValues);
      history.push({
        pathname: history.location.pathname,
        search: `?query=${params.query}&page=${params.page}`,
      });
      return Promise.resolve([]);
    },
    filterOptions,
  );

  const page = useMemo(() => {
    if (queryParams.page) {
      return Number(queryParams.page);
    }
    return 1;
  }, [queryParams]);
  const pageMax = useMemo(() => {
    if (data?.products.pageInfo) {
      return data?.products.pageInfo?.totalPages;
    }
  }, [data]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(`?query=${String(e.target.value)}&page=1`);
    },
    [setSearchValue],
  );

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setSearchValue(`?query=${queryParams.query}&page=${value}`);
    },
    [queryParams.query, setSearchValue],
  );

  useEffect(() => {
    if (error) {
      showToast({
        type: 'error',
        message:
          'Ups, hubo un error al cargar los productos. Intenta de nuevo.',
      });
      console.error({ Error: error.message });
    }
  }, [error, showToast]);

  return (
    <div>
      <h1>Productos</h1>
      <TextField
        id="search"
        label="Producto"
        InputProps={{ startAdornment: <SearchIcon /> }}
        onChange={handleChange}
      />
      <div className={classes.result}>
        {loading && <LinearProgress />}
        {productsList.length > 0 && (
          <div>
            <ProductList data={productsList} />
          </div>
        )}
        <div className={classes.Pagination}>
          <Pagination
            count={pageMax}
            color="primary"
            onChange={handleChangePage}
            page={page}
          />
        </div>

        <Fab
          className={classes.newProduct}
          size="small"
          color="primary"
          variant="round"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon />
        </Fab>
        {open && (
          <CreateProductModal
            open
            setOpen={() => {
              setOpen(false);
            }}
          />
        )}
      </div>
    </div>
  );
}
