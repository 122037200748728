/*
 * File: objects.ts
 * Project: meki
 * File Created: Friday, 30th October 2020 1:53:06 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Thursday, 18th March 2021 5:40:50 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

export const removeUndefineds = (
  object: Record<string, unknown>,
): Record<string, unknown> => {
  const entries = Object.entries(object);
  return entries.reduce((all, [key, val]) => {
    if (val !== undefined) {
      return { ...all, [key]: val };
    }
    return all;
  }, {});
};

interface Omit {
  <T extends Record<string, unknown>, K extends [...(keyof T)[]]>(
    obj: T,
    ...keys: K
  ): {
    [K2 in Exclude<keyof T, K[number]>]: T[K2];
  };
}

export const removeUnuseds: Omit = (obj, ...keys) => {
  const ret = {} as {
    [K in keyof typeof obj]: typeof obj[K];
  };
  let key: keyof typeof obj;
  for (key in obj) {
    if (!keys.includes(key)) {
      ret[key] = obj[key];
    }
  }
  return ret;
};
