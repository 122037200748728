/*
 * File: index.tsx
 * Project: meki
 * File Created: Monday, 14th September 2020 11:27:11 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Thursday, 25th March 2021 3:54:40 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, { useState, useRef } from 'react';
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
  ApolloProvider as ApolloProviderHooks,
} from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/link-error';

import { setContext } from 'apollo-link-context';
import { firebase } from '../../services/firebase';

import { GRAPHQL_URI } from '../environment';
import { GraphQLError } from 'graphql';
import { useDeepEffect } from '@inventures/react-lib';
interface GraphQlError extends GraphQLError {
  extensions: {
    response: {
      body: { errors: { extensions: { message: string; code: string } }[] };
    };
  };
}
interface LinkContext {
  headers: {
    authorization?: string;
    'transaction-id': string;
    useremail: string;
  };
}
export const GlobalApolloClient = { logout: () => {} };

const waitForUser = () => {
  return new Promise((res) => {
    if (firebase.auth().currentUser) {
      return res(true);
    }
    if (typeof window !== 'undefined') {
      const unsuscribe = firebase.auth().onAuthStateChanged(() => {
        unsuscribe();
        res(true);
        // if (timeoutRes) clearTimeout(timeoutRes);
      });
      // const timeoutRes = setTimeout(() => {
      //   res(true);
      //   unsuscribe();
      // }, 1000);
    } else {
      res(true);
    }
  });
};
const getToken = async () => {
  await waitForUser();

  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    return '';
  }

  return currentUser.getIdToken();
};
const clearToken = () => {
  return firebase.auth().signOut();
};

const createClient = () => {
  const httpLink = new HttpLink({ uri: GRAPHQL_URI });

  const authMiddleware = setContext(async (req, { headers }: LinkContext) => {
    const currentToken = await getToken();

    if (currentToken) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${currentToken}`,
        },
      };
    }

    return { headers };
  });

  const errorLink = onError(({ graphQLErrors }: ErrorResponse) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(function (error: unknown): void {
        const extensions = (error as GraphQlError).extensions;
        const invalidAuth =
          extensions?.response?.body?.errors?.some((e) =>
            /invalid_token/.exec(e.extensions?.message),
          ) ||
          extensions?.response?.body?.errors?.some(
            (e) => e.extensions.code === 'UNAUTHENTICATED',
          );

        if (invalidAuth) {
          window.alert('Usuario no registrado o sin permisos');
          GlobalApolloClient.logout();
        }
      });
    }
  });

  const linkWithAuthenticationHeader = from(
    [authMiddleware, errorLink, httpLink].filter(Boolean) as ApolloLink[],
  );
  const cache = new InMemoryCache();

  const apolloClient = new ApolloClient({
    link: linkWithAuthenticationHeader,
    cache,
    ssrMode: typeof window === 'undefined',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
  if (typeof window !== 'undefined') {
    GlobalApolloClient.logout = async () => {
      const currentToken = await getToken();
      // TODO: writeQuery to remove viewerInfo data
      // apolloClient.writeQuery({
      //   query: GET_USER_QUERY,
      //   data: { viewerInfo: null },
      // });
      if (currentToken) {
        await clearToken();
        await apolloClient.cache.reset();
      }
    };
  }

  return apolloClient;
};

type ProviderProps = {
  children: React.ReactNode;
};
export const ApolloProvider = ({ children }: ProviderProps) => {
  const clientRef = useRef(
    new ApolloClient({ uri: GRAPHQL_URI, cache: new InMemoryCache() }),
  );
  const [initialized, setInitialized] = useState(false);
  useDeepEffect(() => {
    const createAndSetClient = () => {
      const newClient = createClient();
      clientRef.current = newClient;
      setInitialized(true);
    };
    void createAndSetClient();
  }, []);
  if (initialized)
    return (
      <ApolloProviderHooks client={clientRef.current}>
        {children}
      </ApolloProviderHooks>
    );
  return null;
};
