/*
 * File: getProduct.ts
 * Project: admin
 * File Created: Tuesday, 16th March 2021 5:44:49 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Friday, 30th April 2021 10:35:01 am
 * Modified By: Vicente Melin (vicente@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { gql } from '@apollo/client';
import { RawProduct } from 'src/interfaces';
import { ResolverParams } from '../../queries.interfaces';

export const getProduct = (name: string) => gql`
  query ${name}($params: ProductParams!) {
    product(params: $params ){
      id
      uuid
      deletedAt
      tradename
      medicineBrand
      price
      laboratory
      categoryId
      imagesUrl
      concentration
      prescriptionType
      unit
      quantity
      package
      routeOfAdministration
      composition
      posology
      display
      contraindications
      indications
      effectivenessPeriod
      prolongedRelease
      barcodes
      metadata {
        ispRegister
        cruzverdeSku
        pharolId
        salcobrandSku
        ligaSku
        manualfId
        vademecumId
        objectID
        fasaSku
        computedAvailability
      }
      tags
      format
      isBioequivalent
      activePrinciple
      container
      containerQuantity
      displayStatus
      computedAvailability: availability( forceCompute: true ) {
        availability
      }
      availability {
        availability
      }
    }
  }
  `;

export type getProductQueryParams = ResolverParams<{
  uuid: string;
}>;

export type getProductQueryResponse = { product: RawProduct };
