/*
 * File: algolia.ts
 * Project: admin
 * File Created: Monday, 1st March 2021 11:26:08 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Tuesday, 9th March 2021 10:22:07 am
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import algoliasearch, { SearchIndex } from 'algoliasearch/lite';

export const algoliaIndexes = (() => {
  if (
    !process.env.REACT_APP_ALGOLIA_APP_ID ||
    !process.env.REACT_APP_ALGOLIA_API_KEY
  ) {
    // eslint-disable-next-line no-console
    console.error('missing algolia config');
    return [];
  }
  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
  );
  const indexes = [
    'isp',
    'cruzverde',
    'pharol',
    'salcobrand',
    'liga',
    'sku',
    'manual_f',
    'vademecum',
  ].map((index): [string, SearchIndex] => {
    return [index, client.initIndex(index)];
  });
  return Object.fromEntries(new Map(indexes));
})();
