/*
 * File: VirtualizedTable.tsx
 * Project: admin
 * File Created: Tuesday, 16th March 2021 1:47:12 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Wednesday, 21st April 2021 1:31:01 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, { useState } from 'react';
import clsx from 'clsx';
import TableCell from '@material-ui/core/TableCell';
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
  WindowScroller,
} from 'react-virtualized';
import { useListStyles } from '../styles';
import { Product } from '../../../interfaces';
import { Chip, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { EditProductModal } from './EditProductModal';

interface ColumnData {
  dataKey: string;
  label: string;
  numeric?: boolean;
}

interface Row {
  index: number;
}

interface VirtualizedTableProps {
  columns: ColumnData[];
  headerHeight: number;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => Product;
  rowHeight: number;
}

export function VirtualizedTable(props: VirtualizedTableProps) {
  const classes = useListStyles();
  const {
    columns,
    rowHeight,
    headerHeight,
    rowCount,
    rowGetter,
    onRowClick,
  } = props;

  const [productUuid, setProductUuid] = useState('');

  const getRowClassName = ({ index }: Row) => {
    // const { onRowClick } = props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  const cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? 'right'
            : 'left'
        }
      >
        {columns[columnIndex].dataKey == 'deletedAt' ? (
          <Chip
            label={cellData ? 'Disable' : 'Enable'}
            color={cellData ? 'secondary' : 'primary'}
            size="small"
          />
        ) : (
          cellData
        )}
      </TableCell>
    );
  };

  const accionsCellRenderer: TableCellRenderer = ({
    rowIndex,
    columnIndex,
  }) => {
    const currentUuid = rowGetter({ index: rowIndex }).uuid;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? 'right'
            : 'left'
        }
      >
        <IconButton
          onClick={() => {
            setProductUuid(currentUuid);
          }}
          aria-label="edit"
        >
          <EditIcon />
        </IconButton>
        {productUuid === currentUuid && (
          <EditProductModal
            open
            setOpen={() => {
              setProductUuid('');
            }}
            productUuid={currentUuid}
          />
        )}
      </TableCell>
    );
  };

  const headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }) => {
    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  const columnsWidth = (dataKey: string, width: number) => {
    switch (dataKey) {
      case 'id':
        return width;
      case 'accions':
        return width;
      default:
        return width;
    }
  };

  return (
    <WindowScroller>
      {({ height, isScrolling, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              autoHeight
              height={height}
              width={width}
              isScrolling={isScrolling}
              onChildScroll={onChildScroll}
              scrollTop={scrollTop}
              rowHeight={rowHeight}
              gridStyle={{
                direction: 'inherit',
              }}
              headerHeight={headerHeight}
              className={classes.table}
              rowCount={rowCount}
              rowGetter={rowGetter}
              onRowClick={onRowClick}
              rowClassName={getRowClassName}
            >
              {columns.map(({ dataKey, ...other }, index) => {
                return (
                  <Column
                    width={columnsWidth(dataKey, width)}
                    key={dataKey}
                    headerRenderer={(headerProps) =>
                      headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    className={classes.flexContainer}
                    cellRenderer={
                      dataKey == 'accions' ? accionsCellRenderer : cellRenderer
                    }
                    dataKey={dataKey}
                    {...other}
                  />
                );
              })}
            </Table>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
}
