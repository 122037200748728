/*
 * File: ProductModal.tsx
 * Project: admin
 * File Created: Friday, 12th March 2021 10:52:02 am
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Monday, 19th April 2021 1:42:36 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useQuery } from '@apollo/client';
import { useToast } from '@inventures/react-lib';
import { Dialog } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Loader } from '../../../components/Loader';
import {
  getProduct,
  getProductQueryParams,
  getProductQueryResponse,
} from '../../../queries/products/queries';
import { ProductForm } from './ProductForm';

interface EditProductModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  productUuid: string;
}

export function EditProductModal({
  open,
  setOpen,
  productUuid,
}: EditProductModalProps) {
  // Hooks init:
  const { showToast } = useToast();
  // console.log({ productUuid });
  const { data, loading, error } = useQuery<
    getProductQueryResponse,
    getProductQueryParams
  >(getProduct('getProduct'), {
    variables: { params: { uuid: productUuid } },
  });

  useEffect(() => {
    if (error) {
      showToast({
        type: 'error',
        message: 'Ups, hubo un error al cargar el producto. Intenta de nuevo.',
      });
      console.error({ Error: error.message });
    }
  }, [error, showToast]);

  const product = data?.product;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
      >
        {loading && <Loader />}
        {product && (
          <ProductForm
            productData={product}
            type={'EDIT'}
            setOpenModal={setOpen}
          />
        )}
      </Dialog>
    </div>
  );
}
