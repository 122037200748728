/*
 * File: CreateProductModal.tsx
 * Project: admin
 * File Created: Wednesday, 17th March 2021 3:03:19 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Tuesday, 27th April 2021 3:47:15 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Dialog } from '@material-ui/core';
import React from 'react';
import { RawProduct } from '../../../interfaces';
import { ProductForm } from './ProductForm';

interface CreateProductModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

type State = Omit<RawProduct, 'category' | 'uuid' | 'id'>;

export function CreateProductModal({ open, setOpen }: CreateProductModalProps) {
  // Hooks init:

  const initialState: State = {
    laboratory: '',
    categoryId: 1,
    imagesUrl: [],
    concentration: '',
    format: '',
    isBioequivalent: false,
    prescriptionType: '',
    activePrinciple: '',
    unit: '',
    quantity: '',
    makerBrand: '',
    barcodes: [],
    metadata: {
      ispRegister: '',
      cruzverdeSku: '',
      pharolId: '',
      salcobrandSku: '',
      ligaSku: '',
      manualfId: '',
      vademecumId: '',
      fasaSku: '',
    },
    package: '',
    routeOfAdministration: '',
    composition: '',
    posology: '',
    display: '',
    contraindications: '',
    indications: '',
    effectivenessPeriod: '',
    prolongedRelease: false,
    tradename: '',
    medicineBrand: '',
    slug: '',
    tags: '',
    container: '',
    containerQuantity: '',
    price: 0,
    enabled: true,
    deletedAt: '',
    displayStatus: '',
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
      >
        <ProductForm productData={initialState} setOpenModal={setOpen} />
      </Dialog>
    </div>
  );
}
