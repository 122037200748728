/*
 * File: ProductForm.tsx
 * Project: admin
 * File Created: Thursday, 18th March 2021 11:00:45 am
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Friday, 30th April 2021 11:04:37 am
 * Modified By: Vicente Melin (vicente@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import {
  useMutation,
  //useQuery
} from '@apollo/client';
import {
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  // Switch,
  // Grid,
  FormGroup,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Checkbox,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  Divider,
  FormLabel,
  FormControlLabel,
} from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { RawProduct, ProductMetadata } from 'src/interfaces';
import {
  UpdateProductMutationResponse,
  UpdateProductMutationParams,
  updateProduct,
  CreateProductMutationResponse,
  CreateProductMutationParams,
  createProduct,
  UpdateMekiPrice,
  UpdateMekiPriceParams,
  UpdateMekiPriceMutationResponse,
} from '../../../queries/products/mutations';
import { useProductStyles } from '../styles';
import { useToast } from '@inventures/react-lib';
import { removeUnuseds } from 'src/utils';
import { AlertModal } from '@inventures/react-lib';
import { ProductImageUploader } from './ProductImage';
// import {
//   getAllCategories,
//   GetAllCategoriesResponse,
// } from '../../../queries/categories/queries/getAllCategories';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface ProductFormProps {
  productData: Omit<RawProduct, 'category' | 'uuid' | 'id'>;
  type?: string;
  setOpenModal: (open: boolean) => void;
}

type State = Omit<RawProduct, 'category' | 'uuid'>;

type Action =
  | {
      type: 'handle-input';
      field: string;
      inputValue: string;
    }
  | {
      type: 'handle-switch';
      field: string;
      inputValue: boolean;
    }
  | {
      type: 'handle-metadata-input';
      field: string;
      key: string;
      inputValue: string;
    }
  | {
      type: 'handle-numeric-input';
      field: string;
      inputValue: number;
    }
  | {
      type: 'prescriptionType-handler';
      field: string;
      inputValue: string | null;
    }
  | {
      type: 'displayStatus-handler';
      field: string;
      inputValue: string | null;
    }
  | {
      type: 'array-handler';
      field: string;
      index?: number;
      inputValue: string[];
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'handle-input':
      if (action.field === 'imagesUrl') {
        return {
          ...state,
          [action.field]: action.inputValue.split(','),
        };
      }
      return {
        ...state,
        [action.field]: action.inputValue,
      };
    case 'handle-switch':
      return {
        ...state,
        [action.field]: action.inputValue,
      };
    case 'handle-metadata-input':
      return {
        ...state,
        [action.field]: { ...state.metadata, [action.key]: action.inputValue },
      };

    case 'array-handler':
      return {
        ...state,
        [action.field]: action.inputValue,
      };

    case 'handle-numeric-input':
      return {
        ...state,
        [action.field]: action.inputValue,
      };

    case 'prescriptionType-handler':
      return {
        ...state,
        [action.field]: action.inputValue,
      };
    case 'displayStatus-handler':
      return {
        ...state,
        [action.field]: action.inputValue,
      };
    default:
      return state;
  }
};

const stateToUpdateParams = (state: State): UpdateProductMutationParams => {
  return {
    params: {
      ...removeUnuseds(
        state,
        'id',
        'price',
        'deletedAt',
        'availability',
        'computedAvailability',
      ),
      imagesUrl: state.imagesUrl.map((e) =>
        /^https/.test(e) ? new URL(e).pathname.slice(1) : e,
      ),
      displayStatus:
        state.displayStatus == 'AUTOMATIC'
          ? state.computedAvailability?.availability
          : state.displayStatus,
      metadata: {
        ...state.metadata,
        computedAvailability: state.displayStatus == 'AUTOMATIC',
      },
    },
  };
};

// enum PrescriptionType {
//   HELD = 'HELD',
//   SIMPLE = 'SIMPLE',
//   NOT_REQUIRED = 'NOT_REQUIRED',
//   CHECK = 'CHECK',
// }

enum DisplayStatusType {
  AVAILABLE_24_HRS = 'AVAILABLE_24_HRS',
  AVAILABLE = 'AVAILABLE',
  TO_CONFIRM = 'TO_CONFIRM',
  WITHOUT_STOCK = 'WITHOUT_STOCK',
  UNAVAILABLE = 'UNAVAILABLE',
  HIDDEN = 'HIDDEN',
  AUTOMATIC = 'AUTOMATIC',
}

enum TranslateDisplayStatus {
  AVAILABLE_24_HRS = 'Disponible 24 hrs',
  AVAILABLE = 'Disponible',
  TO_CONFIRM = 'Por confirmar',
  WITHOUT_STOCK = 'Sin stock',
  UNAVAILABLE = 'No disponible',
  HIDDEN = 'Oculto',
}

export function ProductForm({
  productData,
  type = 'CREATE',
  setOpenModal,
}: ProductFormProps) {
  const classes = useProductStyles();

  // const { data } = useQuery<GetAllCategoriesResponse>(getAllCategories());

  const [newBarcode, setNewBarcode] = useState('');

  const reviver = (key: unknown, value: unknown) =>
    key == '__typename' ? undefined : value;

  const newData = JSON.parse(
    JSON.stringify(productData),
    reviver,
  ) as RawProduct;

  const initialState: State = newData;
  const [barcodes, setBarcodes] = useState<string[]>(
    initialState.barcodes ?? [],
  );

  const { showToast } = useToast();

  const [createProductMutation, createMutationState] = useMutation<
    CreateProductMutationResponse,
    CreateProductMutationParams
  >(createProduct());

  const [updateProductMutation, updateMutationState] = useMutation<
    UpdateProductMutationResponse,
    UpdateProductMutationParams
  >(updateProduct());

  const [updateMekiPrice] = useMutation<
    UpdateMekiPriceMutationResponse,
    UpdateMekiPriceParams
  >(UpdateMekiPrice());

  const loading = useMemo(() => {
    return createMutationState.loading || updateMutationState.loading;
  }, [createMutationState.loading, updateMutationState.loading]);

  const inputRef = useRef<HTMLInputElement | undefined>();

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    enabled: true,
  });

  const prescriptionTypeRef = useRef(state.prescriptionType);

  const priceRef = useRef(state.price);

  const deletedRef = useRef(state.deletedAt);

  useEffect(() => {
    if (deletedRef.current) {
      dispatch({
        type: 'handle-switch',
        field: 'enabled',
        inputValue: false,
      });
    } else {
      dispatch({
        type: 'handle-switch',
        field: 'enabled',
        inputValue: true,
      });
    }
  }, []);

  useEffect(() => {
    if (state.metadata?.computedAvailability) {
      dispatch({
        type: 'displayStatus-handler',
        field: 'displayStatus',
        inputValue: initialState.metadata?.computedAvailability
          ? 'AUTOMATIC'
          : initialState.displayStatus ?? null,
      });
    }
  }, [initialState.metadata?.computedAvailability]);

  const [open, setOpen] = useState(false);

  const [confirmed, setConfirmed] = useState(false);

  const [, setLoading] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    dispatch({
      type: 'handle-input',
      field: e.target.name,
      inputValue: String(e.target.value),
    });
  };

  const handleMetadaInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    dispatch({
      type: 'handle-metadata-input',
      field: 'metadata',
      key: e.target.name,
      inputValue: String(e.target.value),
    });
  };

  // const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   dispatch({
  //     type: 'handle-switch',
  //     field: e.target.name,
  //     inputValue: e.target.checked,
  //   });
  // };

  // const prescriptionTypeHandler = (
  //   e: React.ChangeEvent<{ value: unknown }>,
  // ) => {
  //   dispatch({
  //     type: 'prescriptionType-handler',
  //     field: 'prescriptionType',
  //     inputValue: e.target.value ? String(e.target.value) : null,
  //   });
  // };

  // const categoryHandler = (e: React.ChangeEvent<{ value: unknown }>) => {
  //   dispatch({
  //     type: 'handle-numeric-input',
  //     field: 'categoryId',
  //     inputValue: Number(e.target.value),
  //   });
  // };

  const displayStatusHandler = (e: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: 'displayStatus-handler',
      field: 'displayStatus',
      inputValue: e.target.value ? String(e.target.value) : null,
    });
  };

  const handleSkuInputChange = useCallback(
    (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      index: number,
    ) => {
      if (barcodes) {
        barcodes[index] = e.target.value;
      }
    },
    [barcodes],
  );

  const handleNewSkuInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNewBarcode(String(e.target.value));
    },
    [],
  );

  const handleAddNewSku = useCallback(() => {
    setBarcodes((prevBarcode) => [...prevBarcode, newBarcode]);
    setNewBarcode('');
  }, [newBarcode]);

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key == 'Enter') {
        setBarcodes((prevBarcodes) => [...prevBarcodes, newBarcode]);
        setNewBarcode('');
      }
    },
    [newBarcode],
  );

  const handleDeleteSku = (barcodeToDelete: string) => () => {
    const filtredBarcodes = barcodes.filter(
      (barcode) => barcode != barcodeToDelete,
    );
    setBarcodes(filtredBarcodes);
    dispatch({
      type: 'array-handler',
      field: 'barcodes',
      inputValue: filtredBarcodes,
    });
  };

  const handleCancelChange = useCallback(() => {
    dispatch({
      type: 'prescriptionType-handler',
      field: 'prescriptionType',
      inputValue: prescriptionTypeRef.current,
    });
    setOpen(false);
  }, []);

  const handleConfirmChange = () => {
    setOpen(false);
    setConfirmed(true);
  };

  const handleOnClose = useCallback(() => {
    dispatch({
      type: 'prescriptionType-handler',
      field: 'prescriptionType',
      inputValue: prescriptionTypeRef.current,
    });
    setOpen(false);
    setConfirmed(false);
  }, []);

  const handleChangeLoadingState = useCallback((loadingState) => {
    setLoading(loadingState);
  }, []);

  const handleChangeFileUploaded = useCallback((fileUrl) => {
    dispatch({
      type: 'handle-input',
      field: 'imagesUrl',
      inputValue: String(fileUrl),
    });
  }, []);

  const handleChangeCheckBox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'handle-switch',
        field: event.target.name,
        inputValue: event.target.checked,
      });
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    switch (type) {
      case 'EDIT':
        if (
          prescriptionTypeRef.current !== state.prescriptionType &&
          !confirmed
        ) {
          setOpen(true);
        }
        try {
          if (
            confirmed ||
            prescriptionTypeRef.current == state.prescriptionType
          ) {
            await updateProductMutation({
              variables: {
                ...stateToUpdateParams(state),
              },
            }).then(async (response) => {
              if (priceRef.current !== state.price) {
                await updateMekiPrice({
                  variables: {
                    params: {
                      newMekiPrice: Number(state.price),
                      productId: Number(response.data?.updateProduct.id),
                    },
                  },
                });
              }
              return;
            });
            showToast({
              type: 'success',
              message: 'Producto actualizado con exito.',
            });
            setOpenModal(false);
          }
        } catch (e) {
          showToast({
            type: 'error',
            message:
              'Ups, hubo un error al actualizar el producto. Intenta de nuevo.',
          });
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          console.error({ Error: e });
        }
        break;
      case 'CREATE':
        try {
          await createProductMutation({
            variables: {
              params: {
                ...removeUnuseds(
                  state,
                  'id',
                  'price',
                  'deletedAt',
                  'computedAvailability',
                  'availability',
                ),
                imagesUrl: state.imagesUrl.map((e) =>
                  /^https/.test(e) ? new URL(e).pathname.slice(1) : e,
                ),
                displayStatus:
                  state.displayStatus == 'AUTOMATIC' ? '' : state.displayStatus,
                metadata: {
                  ...state.metadata,
                  computedAvailability: state.displayStatus == 'AUTOMATIC',
                },
              },
            },
          }).then(async (response) => {
            await updateMekiPrice({
              variables: {
                params: {
                  newMekiPrice: Number(state.price),
                  productId: Number(response.data?.createProduct.id),
                },
              },
            });
          });
          showToast({
            type: 'success',
            message: 'Producto creado con exito.',
          });
          setOpenModal(false);
        } catch (e) {
          showToast({
            type: 'error',
            message:
              'Ups, hubo un error al crear el producto. Intenta de nuevo.',
          });
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          console.error({ Error: e });
        }
        break;
      default:
        showToast({
          type: 'error',
          message:
            'Ups, hubo un error. No estas creando o actualizando un producto.',
        });
    }
  }, [
    type,
    state,
    showToast,
    createProductMutation,
    updateProductMutation,
    updateMekiPrice,
    confirmed,
    setOpenModal,
  ]);

  useEffect(() => {
    if (type == 'CREATE') {
      setConfirmed(true);
    }
    if (barcodes.length != 0) {
      dispatch({
        type: 'array-handler',
        field: 'barcodes',
        inputValue: barcodes,
      });
    }
  }, [type, barcodes]);

  return (
    <div>
      <DialogTitle id="form-dialog-title">Producto</DialogTitle>
      <DialogContent>
        <div className={classes.modalForm}>
          <div className={classes.modalImageColumn}>
            <ProductImageUploader
              inputRef={inputRef}
              url={state.imagesUrl[0]}
              onLoadingStateChange={handleChangeLoadingState}
              onFileUploaded={handleChangeFileUploaded}
            />
          </div>
        </div>
        <div className={classes.modalFormEnableProduct}>
          <div>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                Habilitar o Deshabilitar producto.
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.enabled}
                      onChange={handleChangeCheckBox}
                      name={'enabled'}
                      color={'primary'}
                    />
                  }
                  label="Habilitado"
                />
              </FormGroup>
              <FormHelperText>
                Sí, el producto esta deshabilitado no aparecerá en Meki.
              </FormHelperText>
            </FormControl>
          </div>
        </div>
        <div className={classes.modalForm}>
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="tradename-input"
              className={classes.modalTextField}
              defaultValue={state.tradename}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Tradename"
              name={'tradename'}
            />
          </div>
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="medicineBrand-input"
              className={classes.modalTextField}
              defaultValue={state.medicineBrand}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Medicine Brand"
              name={'medicineBrand'}
            />
          </div>
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="activePrinciple-input"
              className={classes.modalTextField}
              defaultValue={state.activePrinciple}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Principio activo"
              name={'activePrinciple'}
            />
          </div>
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="concentration-input"
              className={classes.modalTextField}
              defaultValue={state.concentration}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Concentración"
              name={'concentration'}
            />
          </div>
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="laboratory-input"
              className={classes.modalTextField}
              defaultValue={state.laboratory}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Maker Brand"
              name={'laboratory'}
            />
          </div>
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="format-input"
              className={classes.modalTextField}
              defaultValue={state.format}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Forma Farmacéutica"
              name={'format'}
            />
          </div>
        </div>

        <div className={classes.modalForm}>
          {/* <FormControl variant="outlined" className={classes.textFieldColumn}>
            <InputLabel htmlFor="presctiptionType-label">
              Tipo de Receta
            </InputLabel>
            <Select
              labelId="presctiptionType-label"
              id="prescriptionType-select"
              value={state.prescriptionType ? state.prescriptionType : ''}
              onChange={prescriptionTypeHandler}
              name={'prescriptionType'}
              inputProps={{
                name: 'prescriptionType',
                id: 'presctiptionType-label',
              }}
            >
              <MenuItem value={''}>Sin Receta</MenuItem>
              <MenuItem value={PrescriptionType.NOT_REQUIRED}>
                Simple (No requerida)
              </MenuItem>
              <MenuItem value={PrescriptionType.SIMPLE}>Simple</MenuItem>
              <MenuItem value={PrescriptionType.HELD}>Retenida</MenuItem>
              <MenuItem value={PrescriptionType.CHECK}>Cheque</MenuItem>
            </Select>
            {prescriptionTypeRef.current !== state.prescriptionType &&
            type == 'EDIT' ? (
              <FormHelperText className={classes.warningText}>
                Esta campo no deberia editarse.
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl> 
          */}
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="quantity-input"
              className={classes.modalTextField}
              defaultValue={state.quantity}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Cantidad"
              name={'quantity'}
            />
          </div>
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="unit-input"
              className={classes.modalTextField}
              defaultValue={state.unit}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Unidades"
              name={'unit'}
            />
          </div>
        </div>
        <div className={classes.modalForm}>
          {/* <div className={classes.textFieldColumn}>
            <TextField
              data-testid="package-input"
              className={classes.modalTextField}
              defaultValue={state.package}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Empaque"
              name={'package'}
            />
          </div> */}
          {/* <div className={classes.textFieldColumn}>
            <TextField
              data-testid="routeOfAdministration-input"
              className={classes.modalTextField}
              defaultValue={state.routeOfAdministration}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Vía de administración"
              name={'routeOfAdministration'}
            />
          </div> */}
          {/* <div className={classes.textFieldColumn}>
            <TextField
              data-testid="routeOfAdministration-input"
              className={classes.modalTextField}
              defaultValue={state.composition}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Composición"
              name={'composition'}
            />
            </div> */}
        </div>
        <div className={classes.modalForm}>
          {/* <div className={classes.textFieldColumn}>
            <TextField
              data-testid="posology-input"
              className={classes.modalTextField}
              defaultValue={state.posology}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Posología"
              name={'posology'}
            />
          </div> */}
          {/* <div className={classes.textFieldColumn}>
            <TextField
              data-testid="effectivenessPeriod-input"
              className={classes.modalTextField}
              defaultValue={state.effectivenessPeriod}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Período de vigencia"
              name={'effectivenessPeriod'}
            />
          </div> */}
        </div>
        <div className={classes.modalForm}>
          {/* <div className={classes.textFieldColumn}>
            <TextField
              data-testid="contraindications-input"
              className={classes.modalTextField}
              defaultValue={state.contraindications}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Contraindicaciones"
              name={'contraindications'}
              multiline
            />
          </div> */}
          {/* <div className={classes.textFieldColumn}>
            <TextField
              data-testid="indications-input"
              className={classes.modalTextField}
              defaultValue={state.indications}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Indicaciones"
              name={'indications'}
              multiline
            />
          </div> */}
        </div>
        <div className={classes.modalForm}>
          {/* <div className={classes.textFieldColumn}>
            <TextField
              data-testid="tags-input"
              className={classes.modalTextField}
              defaultValue={state.tags}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Etiquetas"
              name={'tags'}
            />
          </div> */}
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="container-input"
              className={classes.modalTextField}
              defaultValue={state.container}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Envase"
              name={'container'}
            />
          </div>
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="containerQuantity-input"
              className={classes.modalTextField}
              defaultValue={state.containerQuantity}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Cantidad envase"
              name={'containerQuantity'}
            />
          </div>
          <FormControl variant="outlined" className={classes.textFieldColumn}>
            <InputLabel htmlFor="displayStatus-label">
              Display Status
            </InputLabel>
            <Select
              labelId="displayStatus-label"
              id="displayStatus-select"
              value={state.displayStatus}
              onChange={displayStatusHandler}
              name={'displayStatus'}
              inputProps={{
                name: 'displayStatus',
                id: 'displayStatus-label',
              }}
            >
              <MenuItem value={DisplayStatusType.AUTOMATIC}>
                {state.computedAvailability?.availability
                  ? `Automático (${
                      TranslateDisplayStatus[
                        state.computedAvailability
                          .availability as keyof typeof TranslateDisplayStatus
                      ]
                    })`
                  : 'Automático'}
              </MenuItem>
              <MenuItem value={DisplayStatusType.AVAILABLE_24_HRS}>
                Disponible 24 hrs
              </MenuItem>
              <MenuItem value={DisplayStatusType.AVAILABLE}>
                Disponible
              </MenuItem>
              <MenuItem value={DisplayStatusType.TO_CONFIRM}>
                Por confirmar
              </MenuItem>
              <MenuItem value={DisplayStatusType.WITHOUT_STOCK}>
                Sin stock
              </MenuItem>
              <MenuItem value={DisplayStatusType.UNAVAILABLE}>
                No disponible
              </MenuItem>
              <MenuItem value={DisplayStatusType.HIDDEN}>Oculto</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.modalForm}>
          {/* {data?.categories && (
            <FormControl variant="outlined" className={classes.textFieldColumn}>
              <InputLabel htmlFor="categoryId-label">Categoria</InputLabel>
              <Select
                labelId="categoryId-label"
                id="categoryId-select"
                value={state.categoryId ? state.categoryId : ''}
                onChange={categoryHandler}
                name={'categoryId'}
                inputProps={{
                  name: 'categoryId',
                  id: 'categoryId-label',
                }}
              >
                {data.categories.map((category) => {
                  return (
                    <MenuItem
                      key={String(category.id) + `${category.name}`}
                      value={category.id}
                    >
                      {category.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )} */}
        </div>
        {/* <div className={classes.modalForm}>
          <FormGroup className={classes.SwitchForm}>
            <div className={classes.SwitchDiv}>
              <label>Bioequivalente ?</label>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    checked={state.isBioequivalent}
                    onChange={(e) => {
                      handleSwitchChange(e);
                    }}
                    color="primary"
                    name="isBioequivalent"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
                <Grid item>Si</Grid>
              </Grid>
            </div>
            <div className={classes.SwitchDiv}>
              <label>Liberación prolongada ?</label>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    checked={state.prolongedRelease}
                    onChange={(e) => {
                      handleSwitchChange(e);
                    }}
                    color="primary"
                    name="prolongedRelease"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
                <Grid item>Si</Grid>
              </Grid>
            </div>
          </FormGroup>
        </div> */}
        <Divider variant="middle" className={classes.Divider} />
        <div className={classes.modalForm}>
          <Typography variant="subtitle1">Metadata</Typography>
          <Paper variant="outlined" className={classes.modalFormMetada}>
            <div className={classes.modalFormMetada}>
              {state.metadata &&
                (Object.keys(state.metadata) as (keyof ProductMetadata)[])
                  .sort()
                  .map((key: keyof ProductMetadata) => {
                    if (key != 'objectID' && key != 'computedAvailability') {
                      return (
                        <TextField
                          data-testid="metadata-input"
                          className={classes.columnMetadata}
                          defaultValue={state.metadata?.[key] || ''}
                          onChange={(e) => {
                            handleMetadaInputChange(e);
                          }}
                          label={key}
                          name={key}
                          key={key}
                        />
                      );
                    }
                  })}
            </div>
          </Paper>
        </div>
        <Divider variant="middle" className={classes.Divider} />
        <div className={classes.modalForm}>
          {barcodes.length != 0 &&
            barcodes.map((barcode, index) => {
              return (
                <div
                  key={`${barcode}-${index}`}
                  className={classes.textFieldColumn}
                >
                  <TextField
                    data-testid={`sku-input-${index}`}
                    className={classes.modalTextField}
                    defaultValue={barcode}
                    onChange={(e) => {
                      handleSkuInputChange(e, index);
                    }}
                    label={`Código de barra #${index + 1}`}
                    name={'barcode'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleDeleteSku(barcode)}
                            edge="end"
                          >
                            <HighlightOffIcon
                              color="primary"
                              fontSize="small"
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              );
            })}
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="new-barcode-input"
              className={classes.modalTextField}
              value={newBarcode}
              onChange={handleNewSkuInputChange}
              label="Código de Barra"
              name={'new-sku'}
              InputProps={{
                onKeyDown: handleOnKeyDown,
              }}
            />
            <Chip
              color="primary"
              size="small"
              disabled={newBarcode == ''}
              label={'Agregar'}
              onClick={handleAddNewSku}
            />
          </div>
        </div>
        <div className={classes.modalForm}>
          <div className={classes.textFieldColumn}>
            <TextField
              data-testid="price-input"
              className={classes.modalTextField}
              defaultValue={state.price}
              onChange={(e) => {
                handleInputChange(e);
              }}
              label="Precio"
              name={'price'}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          GUARDAR
        </Button>
      </DialogActions>
      <AlertModal
        title="Cambio tipo de receta ⚠️"
        content={'¿Estas seguro de querer cambiar el tipo de Receta?'}
        open={open}
        setOpen={setOpen}
        onClose={handleOnClose}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        actions={[
          {
            text: 'CANCELAR',
            onActionClick: handleCancelChange,
          },
          {
            text: 'ACEPTAR',
            onActionClick: handleConfirmChange,
            variant: 'contained',
          },
        ]}
      />
    </div>
  );
}
