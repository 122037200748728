/*
 * File: ProductImage.tsx
 * Project: admin
 * File Created: Monday, 22nd March 2021 4:50:05 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Friday, 26th March 2021 10:24:50 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import ImageLoader, {
  isLoadFileError,
} from '@inventures/react-lib/components/ImageLoader';
import { Typography } from '@material-ui/core';
import {
  getProductImageUploadLink,
  GetProductImageUploadLinkResponse,
} from '../../../queries/products/queries';
import { uploadFile } from 'src/utils/upload';
import imageCompression from 'browser-image-compression';
import { UploadCloud } from '../../../components/icons';
import { useApolloClient } from '@apollo/client';
import { useToast } from '@inventures/react-lib';
import { useImageLoaderStyles } from '../styles';
import { UpdateModal } from './UpdateModal';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

interface ProductImageUploaderProps {
  url: string;
  inputRef?: MutableRefObject<HTMLInputElement | undefined>;
  onLoadingStateChange?: (loadingState: boolean) => void;
  onFileUploaded?: (fileUrl: string) => void;
}

const UPLOAD_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/jpg',
];
const MAX_FILE_SIZE = 1;

export const ProductImageUploader = ({
  url,
  inputRef,
  onLoadingStateChange,
  onFileUploaded,
}: ProductImageUploaderProps) => {
  // Hooks init
  const client = useApolloClient();
  const { showToast } = useToast();
  const classes = useImageLoaderStyles();

  // App state

  // Local state
  const [file, setFile] = useState('');
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // Variables declaration
  const initialLoadRef = useRef(false);
  const defaultInputRef = useRef<undefined | HTMLInputElement>(undefined);

  // Effects
  useEffect(
    function handleInitialImageUrl() {
      if (initialLoadRef.current) return;
      initialLoadRef.current = true;
      if (url) {
        setFile(url);
        return;
      }
    },
    [url],
  );

  // Logic
  const handleImageLoaderStatusChange = useCallback(
    (newStatus: string) => {
      const handleImageUpload = async () => {
        const getUploadData = async (file: Blob) => {
          const response = await client.query<GetProductImageUploadLinkResponse>(
            {
              query: getProductImageUploadLink('getProductImageUploadLink'),
              fetchPolicy: 'network-only',
              variables: {
                params: { format: file.type.replace(/image\//, '.') },
              },
            },
          );
          return response.data.getProductImageUploadLink;
        };
        setLoading(true);
        try {
          const blobFile = await fetch(file).then((res) => res.blob());
          const uploadData = await getUploadData(blobFile);
          const config = {
            onUploadProgress: (newPercentage: number) => {
              setProgress(newPercentage / 2 + 50);
            },
          };
          const uploadFileResponse = await uploadFile(
            blobFile,
            uploadData,
            true,
            config,
          );
          setLoading(false);
          onFileUploaded?.(uploadFileResponse);
        } catch (e) {
          setLoading(false);
          showToast({
            message: 'Hubo un error al subir la imagen. Intenta de nuevo',
            type: 'error',
          });
          if (file) setFile('');
        }
      };
      if (newStatus === 'LOADED' && file) {
        void handleImageUpload();
      }
      if (newStatus === 'COMPRESSING') {
        onLoadingStateChange?.(true);
      }
    },
    [client, file, showToast, onLoadingStateChange, onFileUploaded],
  );
  const compressImage = useCallback(
    async (file: File) => {
      const options = {
        maxSizeMB: 1,
        useWebWorker: true,
        onProgress: (newProgress: number) => {
          setProgress(newProgress / 2);
        },
      };
      try {
        const compressedFile = await imageCompression(file, options);
        setFile(URL.createObjectURL(compressedFile));
      } catch (error) {
        showToast({
          message: 'Hubo un error cargando el archivo',
          type: 'error',
        });
      }
    },
    [showToast],
  );

  const onError = useCallback(
    (code: unknown) => {
      let message = 'Ha ocurrido un error';
      if (isLoadFileError(code as string)) {
        if (code === 'DOCUMENT_SIZE') {
          message =
            'No se puedo subir el documento. Intenta con uno más liviano';
        }
        if (code === 'UNSUPPORTED_FILE') {
          message = 'Formato no soportado';
        }
      }
      showToast({ message, type: 'error' });
    },
    [showToast],
  );

  const handleCustomClick = React.useCallback(() => {
    if (file !== '') {
      setOpen(true);
    } else {
      (inputRef || defaultInputRef).current?.click();
    }
  }, [file, inputRef]);

  const handleFallbackClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      window.open(file);
    },
    [file],
  );

  return (
    <>
      <ImageLoader
        types={UPLOAD_TYPES}
        maxFileSize={MAX_FILE_SIZE}
        onError={onError}
        compressImage={compressImage}
        file={file}
        setFile={setFile}
        loading={loading}
        progress={progress}
        PreviewFallback={
          <div className={classes.fallback} onClick={handleFallbackClick}>
            <PictureAsPdfRoundedIcon className={classes.fallbackIcon} />
          </div>
        }
        Placeholder={
          <div className={classes.imageText}>
            <UploadCloud className={classes.image} />
            <div className={classes.captionWidth}>
              <Typography className={classes.imageText}>
                Adjunta imagen aqui
              </Typography>
            </div>
          </div>
        }
        handleCustomClick={handleCustomClick}
        ref={inputRef ?? defaultInputRef}
        onStatusChange={handleImageLoaderStatusChange}
      />
      <UpdateModal
        open={open}
        setOpen={setOpen}
        inputRef={inputRef ?? defaultInputRef}
      />
    </>
  );
};
