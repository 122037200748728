/*
 * File: UpdateModal.tsx
 * Project: meki
 * File Created: Tuesday, 26th January 2021 3:02:11 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Tuesday, 23rd March 2021 4:23:31 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, { MutableRefObject } from 'react';
import { AlertModal } from '@inventures/react-lib/components';

interface ProductModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  inputRef: MutableRefObject<HTMLInputElement | undefined>;
}

export function UpdateModal({ open, setOpen, inputRef }: ProductModalProps) {
  // Logic
  const openFileLoader = React.useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);

  const clickYesModal = React.useCallback(() => {
    openFileLoader();
    setOpen(false);
  }, [openFileLoader, setOpen]);

  return (
    <AlertModal
      title="Reemplazo imagen 📷"
      content={'¿Estás seguro que quieres reemplazar la imagen cargada?'}
      open={open}
      setOpen={setOpen}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      actions={[
        {
          text: 'NO',
          onActionClick: () => setOpen(false),
        },
        {
          text: 'SÍ',
          onActionClick: clickYesModal,
          variant: 'contained',
        },
      ]}
    />
  );
}
