/*
 * File: editProduct.ts
 * Project: admin
 * File Created: Wednesday, 17th March 2021 10:02:02 am
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Friday, 30th April 2021 10:35:40 am
 * Modified By: Vicente Melin (vicente@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { RawProduct } from 'src/interfaces';
import { ResolverParams } from '../../queries.interfaces';

export const updateProduct = () => gql`
  mutation($params: UpdateProductParams!) {
    updateProduct(params: $params) {
      id
      uuid
      concentration
      prescriptionType
      unit
      quantity
      package
      routeOfAdministration
      composition
      posology
      display
      contraindications
      indications
      effectivenessPeriod
      prolongedRelease
      metadata {
        ispRegister
        cruzverdeSku
        pharolId
        salcobrandSku
        ligaSku
        manualfId
        vademecumId
        fasaSku
        computedAvailability
      }
      format
      isBioequivalent
      activePrinciple
      laboratory
      displayStatus
    }
  }
`;

export type UpdateProductMutationParams = ResolverParams<
  Omit<
    RawProduct,
    'category' | 'uuid' | 'id' | 'price' | 'deletedAt' | 'availability'
  >
>;

export type UpdateProductMutationResponse = {
  updateProduct: RawProduct;
};
