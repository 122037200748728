/*
 * File: upload.ts
 * Project: meki
 * File Created: Thursday, 5th November 2020 4:06:15 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Friday, 26th March 2021 10:21:17 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import axios from 'axios';

export interface AwsUploadData {
  url: string;
  key: string;
  policy: string;
  successActionStatus: string;
  xAmzAlgorithm: string;
  xAmzCredential: string;
  xAmzDate: string;
  xAmzSignature: string;
}
interface ConfigUploadFile {
  onUploadProgress?: (percentage: number) => void;
}
export async function uploadFile(
  file: Blob,
  uploadData: AwsUploadData,
  isPublic = false,
  config?: ConfigUploadFile,
): Promise<string> {
  const {
    url,
    key,
    policy,
    successActionStatus,
    xAmzAlgorithm,
    xAmzCredential,
    xAmzDate,
    xAmzSignature,
  } = uploadData;
  const formData = new FormData();
  if (isPublic) formData.append('acl', 'public-read');
  formData.append('key', key);
  formData.append('policy', policy);
  formData.append('success_action_status', successActionStatus);
  formData.append('x-amz-algorithm', xAmzAlgorithm);
  formData.append('x-amz-credential', xAmzCredential);
  formData.append('x-amz-date', xAmzDate);
  formData.append('x-amz-signature', xAmzSignature);
  formData.append(
    'file',
    new File([file], key.split('/').slice(-1).join(''), { type: file.type }),
  );

  await axios.request({
    url: `${url}`,
    method: 'POST',
    data: formData,
    headers: {
      Accept: 'application/json',
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      config?.onUploadProgress?.(percentCompleted);
    },
  });
  return key;
}
