/*
 * File: updatePrice.ts
 * Project: admin
 * File Created: Wednesday, 14th April 2021 3:24:34 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Wednesday, 14th April 2021 3:36:11 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { ResolverParams } from '../../queries.interfaces';

export const UpdateMekiPrice = () => gql`
  mutation($params: UpdateMekiPriceParams!) {
    updateMekiPrice(params: $params) {
      amount
      productId
    }
  }
`;

export type UpdateMekiPriceParams = ResolverParams<{
  newMekiPrice: number;
  productId: number;
}>;

export type UpdateMekiPriceMutationResponse = {
  amount: number;
  productId: number;
};
