import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Product } from '../../../interfaces';
import { VirtualizedTable } from './VirtualizedTable';

interface ListProps {
  data: Product[];
}

export default function ProductList({ data }: ListProps) {
  const products = data;

  return (
    <Paper style={{ width: '100%' }}>
      {products && (
        <VirtualizedTable
          rowCount={products.length}
          rowGetter={({ index }) => products[index]}
          headerHeight={48}
          rowHeight={55}
          columns={[
            {
              label: 'Id',
              dataKey: 'id',
              numeric: true,
            },
            {
              label: 'Status',
              dataKey: 'deletedAt',
            },
            {
              label: 'Name',
              dataKey: 'name',
            },
            {
              label: 'Maker Brand',
              dataKey: 'makerBrand',
            },
            {
              label: 'Concentration',
              dataKey: 'concentration',
            },
            {
              label: 'Forma Farmacéutica',
              dataKey: 'format',
            },
            {
              label: 'Cantidad',
              dataKey: 'quantity',
              numeric: true,
            },
            {
              label: 'Unidad',
              dataKey: 'unit',
              numeric: true,
            },
            {
              label: 'Envase',
              dataKey: 'container',
              numeric: true,
            },
            {
              label: 'Cantidad Envase',
              dataKey: 'containerQuantity',
              numeric: true,
            },
            {
              label: 'Accions',
              dataKey: 'accions',
            },
          ]}
        />
      )}
    </Paper>
  );
}
