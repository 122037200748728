/*
 * File: index.tsx
 * Project: admin
 * File Created: Saturday, 27th February 2021 1:55:50 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Saturday, 27th February 2021 1:57:31 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

export function Home() {
  return <div>Home</div>;
}
