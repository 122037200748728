/*
 * File: Upl
 * Project: meki
 * File Created: Tuesday, 26th January 2021 12:32:18 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Saturday, 17th April 2021 12:16:19 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const UploadCloud = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 147 84" width="147px" height="84px" {...props}>
      <g
        id="Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="22" transform="translate(-107.000000, -249.000000)">
          <g
            id="undraw_going_up_ttm5"
            transform="translate(107.000000, 249.000000)"
          >
            <path
              d="M131.183529,47.7769231 C131.379412,47.5 131.466471,47.1538462 131.488235,46.8076923 C131.488235,44.7076923 127.44,43 122.455882,43 C117.471765,43 113.423529,44.7076923 113.423529,46.8076923 C113.423529,47.2 113.554118,47.5692308 113.793529,47.8923077 C108.243529,48.5615385 104.5,49.7846154 104.5,51.1923077 C104.5,53.2923077 112.770588,55 123,55 C133.229412,55 141.5,53.2923077 141.5,51.1923077 C141.5,49.6923077 137.277647,48.4 131.183529,47.7769231 Z"
              id="Path"
              fill="#1C3E71"
              fillRule="nonzero"
              opacity="0.1"
            ></path>
            <path
              d="M105.773629,39.8414634 C102.663689,24.5375 89.2887759,13 72.9996537,13 C60.0596968,13 48.9248095,20.3164634 43.3573659,30.9664634 C29.9824525,32.2868902 19.5,43.6079268 19.5,57.375 C19.5,72.0079268 31.5265482,84 46.2498268,84 L104.207785,84 C116.473559,84 126.499656,74.0210366 126.499656,61.8125 C126.564551,50.1884146 117.452211,40.5341463 105.773629,39.8414634 Z M80.1546887,52.9375 L80.1546887,69.8 L65.8881144,69.8 L65.8881144,52.9375 L50.7298791,52.9375 L73.0214015,30.75 L95.3129239,52.9375 L80.1546887,52.9375 Z"
              id="Shape"
              fill="#0277BD"
            ></path>
            <path
              d="M136.205234,4.77692308 C136.401116,4.5 136.488174,4.15384615 136.509939,3.80769231 C136.509939,1.70769231 132.461713,0 127.477606,0 C122.4935,0 118.445274,1.70769231 118.445274,3.80769231 C118.445274,4.2 118.575862,4.56923077 118.793508,4.89230769 C113.243521,5.53846154 109.5,6.78461538 109.5,8.19230769 C109.5,10.2923077 117.77057,12 127.999958,12 C138.229347,12 146.5,10.2923077 146.5,8.19230769 C146.521681,6.71538462 142.299338,5.4 136.205234,4.77692308 Z"
              id="Path"
              fill="#1C3E71"
              fillRule="nonzero"
              opacity="0.1"
            ></path>
            <path
              d="M27.1835294,7.77692308 C27.3794118,7.5 27.4664706,7.15384615 27.4882353,6.80769231 C27.4882353,4.70769231 23.44,3 18.4558824,3 C13.4717647,3 9.42352941,4.70769231 9.42352941,6.80769231 C9.42352941,7.2 9.55411765,7.56923077 9.79352941,7.89230769 C4.24352941,8.53846154 0.5,9.78461538 0.5,11.1923077 C0.5,13.2923077 8.79235294,15 19,15 C29.2076471,15 37.5,13.2923077 37.5,11.1923077 C37.5,9.71538462 33.2776471,8.4 27.1835294,7.77692308 Z"
              id="Path"
              fill="#1C3E71"
              fillRule="nonzero"
              opacity="0.1"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
