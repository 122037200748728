/*
 * File: index.tsx
 * Project: admin
 * File Created: Sunday, 28th February 2021 7:32:18 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Tuesday, 2nd March 2021 5:34:35 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, createStyles, Typography } from '@material-ui/core';
import { useSearchBar } from '@inventures/react-lib';
import { algoliaIndexes } from 'src/services/algolia';
import { SearchResponse } from '@algolia/client-search';
import { ProductShow } from './Product';

const useStyles = makeStyles(() =>
  createStyles({
    results: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      width: '100%',
      overflowX: 'scroll',
    },
    resultIndex: {
      margin: 15,
      padding: 15,
      minWidth: 500,
      width: 500,
    },
    resultIndexProducts: {
      height: 'calc(100vh - 270px)',
      overflowY: 'scroll',
    },
    indexTitle: {
      textAlign: 'center',
      textTransform: 'capitalize',
      borderBottom: '1px solid black',
      marginBottom: 20,
    },
  }),
);
export function MatchSearch() {
  const classes = useStyles();
  const [, setSearchValue, searchResults] = useSearchBar(
    '',
    async (input: string) => {
      const response = await Promise.all(
        Object.entries(algoliaIndexes).map<
          Promise<[string, SearchResponse<unknown>]>
        >(async ([index, client]) => {
          return [index, await client.search(input, { hitsPerPage: 200 })];
        }),
      );
      return response;
    },
    {
      isCaseSensitive: false,
      debounceTime: 500,
    },
  );
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    },
    [setSearchValue],
  );

  return (
    <div>
      <TextField
        id="search"
        label="Producto"
        InputProps={{ startAdornment: <SearchIcon /> }}
        onChange={handleChange}
      />

      <div className={classes.results}>
        {searchResults.map(([index, response], i) => {
          return (
            <Paper key={i} className={classes.resultIndex}>
              <Typography className={classes.indexTitle} variant="h5">
                {index}
              </Typography>
              <div className={classes.resultIndexProducts}>
                <ProductShow pharmacy={index} data={response.hits} />
              </div>
            </Paper>
          );
        })}
      </div>
    </div>
  );
}
