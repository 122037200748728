/*
 * File: index.ts
 * Project: meki
 * File Created: Thursday, 5th November 2020 3:43:16 pm
 * Author: Vicente Melin (vicente@inventures.cl)
 * -----
 * Last Modified: Monday, 1st March 2021 11:03:50 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useSignInStyles = makeStyles((theme: Theme) => ({
  loginButtons: {
    width: '50%',
    height: '40px',
    alignContent: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    padding: 0,
    '@media (max-width: 380px)': {
      width: '60%',
    },
  },
  loginPaper: {
    display: 'flex',
    width: '100%',
    height: '40px',
    alignItems: 'center',
  },
  loginText: {
    fontSize: '15px',
    fontWeight: 'normal',
    alignSelf: 'center',
  },
  loginIcon: {
    height: 24,
    paddingLeft: 24,
    paddingRight: 16,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    background: theme.palette.primary.main,
  },
}));
