/*
 * File: getProductImageUploadUrl.ts
 * Project: admin
 * File Created: Monday, 22nd March 2021 4:57:27 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Friday, 26th March 2021 9:55:47 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { AwsUploadData } from 'src/utils/upload';

export const getProductImageUploadLink = (name: string) => gql`
  query ${name}($params: UploadLinkParams!) {
    getProductImageUploadLink(params: $params)
  }
`;

export interface GetProductImageUploadLinkResponse {
  getProductImageUploadLink: AwsUploadData;
}
