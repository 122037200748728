/*
 * File: useAuth.ts
 * Project: admin
 * File Created: Sunday, 28th February 2021 8:16:13 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Thursday, 25th March 2021 3:55:08 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useEffect, useMemo, useRef, useState } from 'react';
import { firebase } from '../services/firebase';
import { User } from '@firebase/auth-types';
import { useToast } from '@inventures/react-lib';

interface UseAuthResponse {
  user?: User;
  loading: boolean;
}

export function useAuth(): UseAuthResponse {
  const { showToast } = useToast();

  const [user, setUser] = useState<undefined | User>(undefined);
  const [loading, setLoading] = useState(true);

  const showToastRef = useRef(showToast);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedUser = useMemo(() => user, [user?.email]);

  useEffect(() => {
    showToastRef.current = showToast;
  }, [showToast]);
  useEffect(() => {
    const onAuthStateChange = () => {
      return firebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          if (
            ['@inventures.cl', '@mimeki.cl'].every(
              (domain) => !firebaseUser?.email?.match(domain),
            )
          ) {
            showToastRef.current({
              message: 'Usuario no autorizado',
              type: 'error',
            });
            void firebase.auth().signOut();
            return;
          }
          setUser(firebaseUser);
        } else {
          setUser(undefined);
        }
        setLoading(false);
      });
    };
    const unsubscribe = onAuthStateChange();
    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (!memoizedUser?.email) return;
    if (
      ['@inventures.cl', '@mimeki.cl'].every(
        (domain) => !memoizedUser?.email?.match(domain),
      )
    ) {
      showToastRef.current({ message: 'Usuario no autorizado', type: 'error' });
      void firebase.auth().signOut();
    }
  }, [memoizedUser?.email]);

  return { user: memoizedUser, loading };
}
