/*
 * File: firebase.ts
 * Project: meki
 * File Created: Monday, 28th September 2020 12:37:18 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Sunday, 28th February 2021 9:56:43 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { firebase as firebaseApp } from '@firebase/app';
import { FirebaseNamespace } from '@firebase/app-types';
import '@firebase/auth';
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE,
  FIREBASE_PROJECT_ID,
  FIREBASE_SENDER_ID,
  FIREBASE_STORAGE_BUCKET,
} from '../config/environment';

export const firebase: Required<FirebaseNamespace> = (() => {
  if (firebaseApp.apps.length === 0) {
    firebaseApp.initializeApp({
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_AUTH_DOMAIN,
      databaseURL: FIREBASE_DATABASE,
      projectId: FIREBASE_PROJECT_ID,
      appId: FIREBASE_APP_ID,
      storageBucket: FIREBASE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_SENDER_ID,
    });
    firebaseApp.auth?.().useDeviceLanguage();
  }
  return firebaseApp as Required<FirebaseNamespace>;
})();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export const signinWithGoogle = () => {
  void firebase.auth().signInWithRedirect(googleProvider);
};

export const getRedirectResult = async () => {
  try {
    const result = await firebase.auth().getRedirectResult();
    return Boolean(result.user);
  } catch (error) {
    return false;
  }
};
