/*
 * File: styles.ts
 * Project: admin
 * File Created: Friday, 12th March 2021 1:06:53 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Wednesday, 21st April 2021 2:27:59 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { createStyles, makeStyles } from '@material-ui/core/styles';
import theme from 'src/config/styles/theme';

export const useProductStyles = makeStyles(() =>
  createStyles({
    productContainer: {
      borderBottom: '1px solid #56505047',
      marginBottom: 25,
    },
    image: {
      width: 200,
      objectFit: 'contain',
      display: 'block',
    },
    root: {
      maxWidth: 345,
      marginBottom: 20,
      marginTop: 20,
    },
    list: {
      width: '100%',
      height: '100vh',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[3],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 5,
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    modalForm: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      '@media (mmax-width: 800px)': {
        flexDirection: 'column',
      },
      flexWrap: 'wrap',
    },
    modalFormEnableProduct: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      '@media (mmax-width: 800px)': {
        flexDirection: 'column',
      },
      flexWrap: 'wrap',
      padding: 15,
    },
    modalFormMetada: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      '@media (mmax-width: 800px)': {
        flexDirection: 'column',
      },
      flexWrap: 'wrap',
      marginBottom: 15,
    },
    AddButtonForm: {
      margin: 'auto',
    },
    columnMetadata: {
      margin: 10,
      flex: 0,
      flexBasis: '31%',
    },
    modalImageColumn: {
      maxWidth: '50%',
      margin: '10px auto',
      maxHeight: '80%',
    },
    modalTextField: {
      marginBottom: '16px',
    },
    textFieldColumn: {
      margin: 10,
      flex: 1,
      flexBasis: '35%',
    },
    newProduct: {
      position: 'fixed',
      right: 0,
      marginRight: 16,
      bottom: 64,
    },
    result: {
      paddingTop: 20,
    },
    SwitchDiv: {
      paddingBottom: 10,
      marginRight: 20,
    },
    SwitchForm: {
      margin: 'auto',
    },
    warningText: {
      color: '#ffb74d',
    },
    formControl: {
      width: '100%',
    },
    Pagination: {
      justifyContent: 'center',
      display: 'flex',
      paddingTop: 10,
    },
    Divider: {
      marginBottom: 10,
    },
  }),
);

export const useListStyles = makeStyles(() => {
  return createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    table: {
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      '& .ReactVirtualized__Table__headerRow': {
        flip: false,
        paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
      },
    },
    tableRow: {
      cursor: 'pointer',
    },
    tableRowHover: {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      flex: 1,
    },
    noClick: {
      cursor: 'initial',
    },
  });
});

export const useImageLoaderStyles = makeStyles(() => {
  return createStyles({
    image: {
      width: 177,
      height: 104,
      marginBottom: 12,
    },
    imageText: {
      fontSize: 13,
      color: theme.palette.primary.light,
      textAlign: 'center',
    },
    marginLoader: {
      marginLeft: 7,
      marginRight: 17,
    },
    captionWidth: {
      width: 176,
    },
    fallback: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fallbackIcon: {
      fill: theme.palette.primary.main,
      marginBottom: '16px',
      height: 64,
      width: 64,
    },
  });
});
