/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * File: Product.ts
 * Project: admin
 * File Created: Monday, 1st March 2021 11:42:11 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Tuesday, 9th March 2021 12:18:14 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { createStyles, makeStyles, Typography } from '@material-ui/core';
interface ProductShowProps {
  pharmacy: string;
  data: Record<string, any>[];
}
const useStyles = makeStyles(() =>
  createStyles({
    productContainer: {
      borderBottom: '1px solid #56505047',
      marginBottom: 25,
    },
    image: {
      width: 200,
      objectFit: 'contain',
      display: 'block',
    },
  }),
);

export function ProductShow({ pharmacy, data }: ProductShowProps) {
  const classes = useStyles();
  if (pharmacy === 'liga')
    return (
      <>
        {data.map((e) => (
          <div className={classes.productContainer} key={e.key}>
            <Typography variant="h6">{e.metadata.name}</Typography>
            <Typography variant="body1">{e.metadata.Laboratorio}</Typography>
            <Typography variant="body1">SKU: {e.key}</Typography>
            <img className={classes.image} src={e.metadata.imageUrl} />
            <a target="_blank" rel="noreferrer" href={e.metadata.productUrl}>
              Ver
            </a>
          </div>
        ))}
      </>
    );
  if (pharmacy === 'isp')
    return (
      <>
        {data.map((e) => (
          <div className={classes.productContainer} key={e.objectID}>
            <Typography variant="h6">{e.Nombre}</Typography>
            <Typography variant="body1">{e.Registro}</Typography>
            <Typography variant="body1">{e.Titular}</Typography>
            <Typography variant="body1">
              <strong>{e['Estado del Registro'].split('.')[1]}</strong>
            </Typography>
            <a
              target="_blank"
              rel="noreferrer"
              href={`http://registrosanitario.ispch.gob.cl/Ficha.aspx?RegistroISP=${e.RegistroFull}`}
            >
              Ver
            </a>
          </div>
        ))}
      </>
    );
  if (pharmacy === 'sku')
    return (
      <>
        {data.map((e) => (
          <div className={classes.productContainer} key={e.objectID}>
            <Typography variant="h6">{e.description}</Typography>
            <Typography variant="body1">{e.maker}</Typography>
            <Typography variant="body1">EAN13: {e.EAN13}</Typography>
          </div>
        ))}
      </>
    );
  if (pharmacy === 'meki')
    return (
      <>
        {data.map((e) => (
          <div className={classes.productContainer} key={e.objectID}>
            <Typography variant="h6">
              {e.medicine_brand ?? e.tradename}
            </Typography>
            <Typography variant="body1">ID: {e.id}</Typography>
            <Typography variant="body1">{e.concentration}</Typography>
          </div>
        ))}
      </>
    );
  if (pharmacy === 'vademecum')
    return (
      <>
        {data.map((e) => (
          <div className={classes.productContainer} key={e.objectID}>
            <Typography variant="h6">{e.name}</Typography>
            <Typography variant="body1">ID: {e.ID}</Typography>
            <ul>
              <Typography component="li" variant="body1">
                <strong>Laboratorio</strong>: {e.lab}
              </Typography>
              <Typography component="li" variant="body1">
                <strong>Descripción</strong>: {e.description}
              </Typography>
              <Typography component="li" variant="body1">
                <strong>Composición</strong>: {e.Composición}
              </Typography>
              <Typography component="li" variant="body1">
                <strong>Presentación</strong>: {e.Presentación}
              </Typography>
            </ul>
          </div>
        ))}
      </>
    );
  if (pharmacy === 'manual_f')
    return (
      <>
        {data.map((e) => (
          <div className={classes.productContainer} key={e.objectID}>
            <Typography variant="h6">{e.name}</Typography>
            <Typography variant="body1">ID: {e.ID}</Typography>
            <Typography variant="body1">{e.Laboratorio}</Typography>
            <Typography variant="body1">{e.Presentaciones}</Typography>
            <ul>
              <Typography component="li" variant="body1">
                <strong>Presentacion:</strong> {e.Presentaciones}
              </Typography>
              <Typography component="li" variant="body1">
                <strong>Composición:</strong> {e.Composición}
              </Typography>
            </ul>
          </div>
        ))}
      </>
    );
  if (pharmacy === 'pharol') {
    const filteredData = Array.from(
      new Map(data.map((e) => [e.metadata.id_pharol, e])).values(),
    );
    return (
      <>
        {filteredData.map((e) => (
          <div className={classes.productContainer} key={e.objectID}>
            <Typography variant="h6">{e.metadata.name}</Typography>
            <Typography variant="body1">EAN13: {e.metadata.ean13}</Typography>
            <Typography variant="body1">
              ID Pharol: {e.metadata.id_pharol}
            </Typography>
            <Typography variant="body1">{e.metadata.laboratory}</Typography>
            <img className={classes.image} src={e.metadata.image} />
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.pharol.cl/products/${e.metadata.slug}`}
            >
              Ver
            </a>
          </div>
        ))}
      </>
    );
  }
  if (pharmacy === 'cruzverde')
    return (
      <>
        {data.map((e) => (
          <div className={classes.productContainer} key={e.objectID}>
            <Typography variant="h6">{e.metadata.name}</Typography>
            <Typography variant="body1"> {e.metadata.laboratory}</Typography>
            <Typography variant="body1">SKU: {e.key}</Typography>
            <img className={classes.image} src={e.metadata.imageUrl} />
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.cruzverde.cl/${e.key}.html`}
            >
              Ver
            </a>
          </div>
        ))}
      </>
    );
  if (pharmacy === 'salcobrand')
    return (
      <>
        {data.map((e) => (
          <div className={classes.productContainer} key={e.objectID}>
            <Typography variant="h6">{e.metadata.name}</Typography>
            <Typography variant="h6">{e.metadata.info}</Typography>
            <Typography variant="h6">{e.metadata.laboratory}</Typography>
            <Typography variant="body1">
              Formato: {e.metadata.format}
            </Typography>
            <Typography variant="body1">
              Descripción: {e.metadata.description}
            </Typography>
            <Typography variant="body1">SKU: {e.key}</Typography>
            <img className={classes.image} src={e.metadata.imageUrl} />
            <a
              target="_blank"
              rel="noreferrer"
              href={e.metadata.productUrl.split('&taxon_id')[0]}
            >
              Ver
            </a>
          </div>
        ))}
      </>
    );

  return <pre>{JSON.stringify(data, null, 2)}</pre>;
}
