/*
 * File: login.tsx
 * Project: meki
 * File Created: Wednesday, 7th October 2020 2:21:48 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Monday, 1st March 2021 11:05:00 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { useCallback, useState, useEffect } from 'react';

import { useSignInStyles } from './styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { signinWithGoogle, getRedirectResult } from '../../services/firebase';
import googleIcon from '../../assets/googleIcon.png';
import { Loader } from 'src/components/Loader';

export function SignIn() {
  // Hooks init

  const classes = useSignInStyles();

  // App State

  //Local State

  const [awaitGoogle, setAwaitGoogle] = useState(false); // is true if I'm waiting for google

  // Other varriable declaration (useRef, etc)

  // Effects

  useEffect(() => {
    const handleRedirectResult = async () => {
      setAwaitGoogle(true);
      await getRedirectResult();
      setAwaitGoogle(false);
    };
    void handleRedirectResult();
  }, []);

  const connectWithGoogle = useCallback(() => {
    signinWithGoogle();
    setAwaitGoogle(true);
  }, []);

  return (
    <div className={classes.content}>
      <Button
        className={classes.loginButtons}
        onClick={connectWithGoogle}
        disabled={awaitGoogle}
      >
        <Paper className={classes.loginPaper}>
          <img src={googleIcon} className={classes.loginIcon} />
          <Typography
            variant="subtitle1"
            color={'textPrimary'}
            className={classes.loginText}
          >
            Conectar con Google
          </Typography>
        </Paper>
      </Button>
      {awaitGoogle && <Loader />}
    </div>
  );
}
